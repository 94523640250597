import React, {Component} from 'react';
import './AssetSelectorButton.css'

export default class AssetSelectorButton extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {asset_data: props.asset_data, indx: props.indx};
  }

  render() {
    // console.log(this.state.players)
    let ast = this.state.asset_data;
    let class_name = "AssetSelectorButton " + this.state.indx;
    return (
      <div className={class_name} key={ast.id} id={ast.id} onClick={this.props.select_asset_function}>
        <span className="asset_symbol_in_list" id={ast.id}>{ast.symbol}</span>
        <span className="asset_name_in_list" id={ast.id}>{ast.name}</span>
      </div>
    )
  }
}
