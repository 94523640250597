import React, {Component} from 'react';
import {draw,draw_articles} from './d3_hype_graph_2.js'
import './HypeGraph.css'

export default class HypeGraph extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.articles = "";
  }
  draw_graph() {
    draw(this.props);
  }

  updateDimensions() {
    this.draw_graph();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.draw_graph();
  }

  componentDidUpdate() {
    draw_articles(this.props);
    this.draw_graph();
    console.log('UPATING component')
  }



  render() {
    return (
      <div className="HypeGraph">
        <div id="dkviz" className="viz">
          <svg preserveAspectRatio="xMinYMid meet" id="mainSvg"><g></g></svg>
        </div>
      </div>
    )
  }
}
