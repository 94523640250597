import React, {Component} from 'react';
import './AssetsList.css'
import AssetSelectorButton from './AssetSelectorButton.js'
import * as d3 from 'd3';

export default class AssetsList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    let selected_asset_id = props.selected_asset_id;
    this.state = {assets: props.assets_list, selected_asset_id: selected_asset_id};


    this.initUpdateWindowDimensions = this.updateWindowDimensions.bind(this)
    d3.timeout(this.updateWindowDimensions.bind(this),200);

  }

  is_selected(ast_id){
    if (ast_id === this.state.selected_asset_id) {
      return true
    } else {
      return false
    }
  }

    componentDidMount () {
      this.initUpdateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions () {
      if(d3.select("#mainSvg").node()) {
         const svgH = d3.select("#mainSvg").node().getBoundingClientRect().height;
         d3.select("#AssetsList").style('height', (svgH + 10) + 'px');
      }
    }

  setFilter = (e) => {
    const s = e.target.value,
      rgxp = new RegExp('^' + s, "i"),
      filtered = this.props.assets_list.filter(d => d.symbol.match(rgxp) || d.name && d.name.match(rgxp));
    this.setState({assets: filtered })

  }  

  select_asset_function(ast_id) {
    this.is_selected(ast_id)
    // this.props.set_player_function;
  }

  render() {
    return (
      <div>
        <div className="inputAsset"><input placeholder='Search' onInput={this.setFilter} className="AssetSelectorButton" type="text"></input></div>
      <div className="AssetsList" id="AssetsList">
        {this.state.assets.map((ast, indx) =>

          <AssetSelectorButton
            key={ast.id}
            asset_data={ast}
            select_asset_function={this.props.select_asset_function}
            indx={(indx % 2) == 0 ? 'asset_button_grey' : 'asset_button_white'}
          />
        )}
      </div>
      </div>
    )
  }
}
