import React, { Component } from 'react';
import './HiMain.css';
import HypeGraph from './HypeGraph.js'
import AssetsList from './AssetsList.js'

const axios = require('axios');

export default class HiMain extends Component {
  constructor(props) {
    super(props);
    this.state = {init_data: null, current_asset: null, asset_articles: null};
    let res = this;
    // axios.get('http://35.228.90.224/frontend')
    axios.get('https://serve.hypeindex.io/frontend/assets_list')
    // axios.get('http://localhost:3001/frontend/assets_list')
      .then(function(response){
        // res.setState({init_data: response.data});
        response.data.sort((a,b) => a.symbol.localeCompare(b.symbol));
        res.setState({assets_list: response, current_asset : response.data[0]});
        res.get_asset_data(response.data[0].id);
      })
  }


  get_asset_details(ast_id) {
    let res = this;
    return axios.get('https://serve.hypeindex.io/frontend/'+ast_id+'/asset_articles')
    // return axios.get('http://localhost:3001/frontend/'+ast_id+'/asset_articles')
      .then((response) => 
        res.setState({asset_articles: response.data})
    )
  }  

  get_asset_data(ast_id) {
    let res = this;
    // axios.get('http://35.228.90.224/frontend/'+ast_id+'/asset')
    // axios.get('http://localhost:3001/frontend/'+ast_id+'/asset')
    axios.get('https://serve.hypeindex.io/frontend/'+ast_id+'/asset')
      .then(function(response){
        const asset =  res.state.assets_list.data.find(d => d.id == ast_id);
        res.get_asset_details(asset.id).then(() => {
          res.setState({init_data: response, current_asset: asset});
        })

      })
      
  }

  set_asset_function = (evt) => {
    this.get_asset_data(evt.target.id)
  }


  render() {
    if (!this.state.init_data){
        return <div>Loading...</div>
    }


    return (

      <div className="HiMain">
        <AssetsList assets_list={this.state.assets_list.data} select_asset_function={this.set_asset_function} />
        <div className="middle_section">
          <HypeGraph hype_data={this.state.init_data.data.data} price_data={this.state.init_data.data.price_data} asset={this.state.current_asset} article_data={this.state.asset_articles}/>
          </div>
      </div>



    )
  }
}
