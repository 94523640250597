import React from 'react';
import './App.css';
// import PlayerPage from './PlayerPage';
import HiMain from './HiMain';
// import ThreeMain from './ThreeMain';
// import { observable } from 'mobx';

const App = () => {
  return (<div className="App">
            <HiMain />
          </div>);
};

export default App;