import * as d3 from 'd3';

const article_area_width = 190;
const articles_gap = 10;

const draw_articles = (props, date = false) => {

    const formatArticleDate = d3.timeFormat("%Y-%m-%d"),
        dateConverted = formatArticleDate(date);
    const artdata = d3.select("#article-data");
    const article_data = props.article_data[dateConverted];

    var tdata = artdata 
        .selectAll('.art-items')
        .data(article_data || [], d => d.id);


    if(article_data && article_data.length) {
       
        var tdata_enter = tdata.enter()
            .append("text")
            .attr("class","art-items")
            .attr('art-id', d => d.id)
            .attr('y', (d,i) => i * 20 + 20)
            .attr('dx', 5)
            .style('opacity',0.1)
            .text(d => d.headline)
            .attr("mask", "url(#Mask)")
            .call(wrapText, article_area_width)
            .call(reArrange, 15, articles_gap)

        tdata.merge(tdata_enter);

    }else if(date) {
        return;
    }

    tdata.exit().transition().duration(200).style('opacity',0).remove();   
    d3.select('.article-bg').transition().duration(100).style('opacity',  article_data && article_data.length)
        .style('height', article_data && article_data.length ? null: '0px')

}  


function reArrange(text, start, len) {
    let Y = start;
    text.each(function() {
        const textEl = d3.select(this),
            h = textEl.node().getBoundingClientRect().height;
        textEl.transition().duration(200).attr('y', Y).style('opacity',1);
        Y += len + h;    
    })    
}    

function wrapText(text, width) {
    text.each(function(d) {
        let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          dx = text.attr("dx"),

          tspan = text.text(null) 
            .append("tspan") 
                .classed(d.sentiment < 0 ? 'red-text' : 'green-text', true)
                .text(d.sentiment.toFixed(2));

            tspan = text.append("tspan").attr("dy", "1em").attr('x',dx)
           
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
          .attr('x',dx)
          .attr("dy",  "1em").text(word);
        }
      }
    });
  }

function clearArticles() {
    d3.select("#article-data").style('opacity',0)
    d3.select("#article-data").remove();
}  

const draw = (props ) => {


  var data = props.hype_data;
  var price_data = props.price_data;
  var asset = props.asset;
  var svg //, tooltip, legend;

  // var assets_list = [];
  // d3.json('https://serve.hypeindex.io/frontend/assets_list.json').then( data => {
  //       console.log(data);
  //       assets_list = data
  //   })
  // d3.json('https://serve.hypeindex.io/frontend/assets_list', function(error, data) {
  //   console.log('data');
  //   console.log(data);
  //   console.log(' data dkk data dkk data dkk');
  //   // d3.select("body")
  //   //     .selectAll("p")
  //   //     .data(data)
  //   //     .enter()
  //   //     .append("p")
  //   //     .text(function(d) {
  //   //         return d.name + " : " + d.symbol;
  //   //     });

  //   });

  const W =  1200, H = 700, mainW = W - 200;
  const legendFields = ['hype','price','datetime','volume'];
  let dateSelected = false;

  // set the dimensions and margins of the graph
  var margin = {top: 20, right: 80, bottom: 30, left: 50},
      width = mainW - margin.left - margin.right,
      height = H - margin.top - margin.bottom,
      heightTop = 130,
      heightMain = 300,
      heightMainStart = heightTop + 10,
      heightBarSection = 160,
      heightBarSectionStart = heightMainStart + heightMain + 30,
      bisectDate = d3.bisector(d => d.dateParsed ).left;


  // parse the date / time
  var parseTime = d3.timeParse("%Y-%m-%d %H:%M"),
      formatTime = d3.timeFormat("%b %e, %Y");


  // parse dates and store parsed date/time values
  data.map(d => d.dateParsed = parseTime(d.datetime));
  price_data.map(d => d.dateParsed = parseTime(d.datetime));

  // set the ranges
  var x = d3.scaleTime().range([0, width]);
  var xhype = d3.scaleTime().range([0, width]);
  var y0 = d3.scaleLinear().range([heightMain + heightMainStart, heightMainStart]);
  var y0ra = d3.scaleLinear().range([heightMain + heightMainStart, heightMainStart]);
  var y1 = d3.scaleLinear().range([heightMain + heightMainStart, heightMainStart]);
  var yBars = d3.scaleLinear().range([heightBarSection, 0]);

  var xBarScale = d3.scaleBand()
    .range([0, width])
    .paddingInner(0.09)
    .domain(price_data.map(d => d.dateParsed));


    // Scale the range of the data
    x.domain(d3.extent(price_data,  d => parseTime(d.datetime) ));
    xhype.domain(d3.extent(data,  d => parseTime(d.datetime) ));
    y0.domain([0, d3.max(data, d => +d.hype_rolling_avg_small) ]);
    y0ra.domain([0, d3.max(data, d => +d.hype_rolling_avg) ]);
    y1.domain([0, d3.max(price_data, d => +d.price )]);
  //  yBars.domain([0, d3.max(data, d => +d.volume) ]);
    yBars.domain(d3.extent(data, d => +d.volume) ).nice();


    var valueline = d3.line()
        .curve(d3.curveLinear)
        .x(d => xhype(d.dateParsed) )
        // .y(d => y0(+d.hype_rolling_avg_small));
        .y(d => y0(+d.hype))
        .curve(d3.curveBasis);
        // .interpolate("cardinal");

    var valueline_rolling_avg = d3.line()
        .curve(d3.curveLinear)
        .x(d => x(d.dateParsed) )
        .y(d => y0(+d.hype_rolling_avg_big));
        // .curve(d3.curveBasis);
        // .interpolate("cardinal");


    var valueline2 = d3.line()
        .curve(d3.curveLinear)
        .x(d => x(d.dateParsed) )
        .y(d => y1(+d.price))
        .curve(d3.curveBasis);


  // append the svg object to the body of the page
  // appends a 'group' element to 'svg'
  // moves the 'group' element to the top left margin

    var viz = d3.select(".viz");
    viz.select('svg')
    // .on("click", () => console.log('CLEANING') +  )
     //   .attr("id","mainSvg")
    //  .attr("width", width + margin.left + margin.right)
    //  .attr("height", height + margin.top + margin.bottom)
    .attr('viewBox', `0 0 ${W} ${H}`)
    .on('mousemove', () => {
        if(dateSelected) return false;
       
        const coords = d3.mouse(svg.node());
        if(coords[0] > width || coords[0] <=0 ) return false;   

        const X =  coords[0],
        Xi = x.invert(X),
        line1 = d3.select('.line1').node(),
        // line1ra = d3.select('.line1ra').node(),
        line2 = d3.select('.line2').node(),
        pc1 = d3.select('#pc1inner'),
        pc1outer = d3.select('#pc1outer'),
        pc2 = d3.select('#pc2inner'),
        pc2outer = d3.select('#pc2outer'),
        Y1 = findPos(line1,X),
        // Y1ra = findPos(line1ra,X),
        Y2 = findPos(line2,X),
        price_data_intersect = bisectDate(price_data, Xi, 1),
        hype_data_intersect = bisectDate(data, Xi, 1),
        d0 = price_data[price_data_intersect - 1],
        d1 = price_data[price_data_intersect],

        hype_data_point_left = data[hype_data_intersect - 1],
        hype_data_point_right = data[hype_data_intersect],

        YY1 = y0.invert(Y1),
        YY2 = y1.invert(Y2),
        d = Xi - d0.dateParsed > d1.dateParsed - Xi ? d1 : d0,
        resolved_data_position_hype = Xi - hype_data_point_left.dateParsed > hype_data_point_right.dateParsed - Xi ? hype_data_point_right : hype_data_point_left;
     //   console.log( 
        // console.log(Y1, y1.invert(Y1));
        //d = Xi - d0.year > d1.year - Xi ? d1 : d0;

        pc1.attr('cx', X).attr('cy', Y1).style('opacity',1);
        pc1outer.attr('cx', X).attr('cy', Y1).style('opacity',1);
        pc2.attr('cx', X).attr('cy', Y2).style('opacity',1);
        pc2outer.attr('cx', X).attr('cy', Y2).style('opacity',1);

        const barpos = xBarScale(d.dateParsed);
        const barY = svg.selectAll(".bar").classed("selected",false)
        .filter(d => d.x >= barpos && d.x < barpos + xBarScale.bandwidth())
        .classed("selected","true").attr('y');


        // d3.select('.p-volume').style("opacity",1).attr('y', barY-5).attr('x', barpos + xBarScale.bandwidth()/2).text(d.volume.toLocaleString());

        d3.select('.p-vert').attr('d', `M${X},${heightMainStart} v${heightMain}`).style('opacity',1);
        d3.select('#p-hor1').attr('d', `M${X},${Y1} H5`).style('opacity',1);
        d3.select('#p-hor2').attr('d', `M${X},${Y2} H${width-5}`).style('opacity',1);

        d3.select('#left_axis_text_marker').style('opacity',1);
        d3.select("#axis_text_marker_box").attr('y',(Y1-12)).raise(); // hype
        d3.select("#axis_text_marker_dot").attr('y',(Y1-3)).raise(); // hype
        d3.select("#axis_text_marker_text").attr('x', -75).attr('y',(Y1-1)).text(YY1.toFixed(2)).raise(); // hype

        d3.select('#right_axis_text_marker').style('opacity',1);
        d3.select("#right_axis_text_marker_box").attr('x', width-10).attr('y',(Y2-12)).text(YY2.toFixed(2)).raise(); // price
        d3.select("#right_axis_text_marker_dot").attr('x', width-3).attr('y',Y2-3).text(YY2.toFixed(2)).raise(); // price
        d3.select("#right_axis_text_marker_text").attr('x', width-31).attr('y',(Y2-1)).text('$'+YY2.toFixed(2)).raise() // price

        d3.select('#bottom_axis_text_marker').style('opacity',1);
        d3.select("#bottom_axis_text_marker_box").attr('x', coords[0] - 40).attr('y',heightMainStart + heightMain + 2); // date
        d3.select("#bottom_axis_text_marker_text").attr('x', coords[0] - 72).attr('y',heightMainStart + heightMain + 13).text(formatTime(Xi)); // date

        var current_date = svg.select('.meta_box_date_text');
            current_date.raise().text(formatTime(Xi) || '');

        var current_price = svg.select('.meta_box_price_text');
            current_price.raise().text(parseFloat(d.price) ? ('$' + parseFloat(d.price).toFixed(2)) : '' );

        var current_hype = svg.select('.meta_box_hype_text');
            // current_hype.raise().text((parseFloat(d.hype) ? parseFloat(d.hype).toFixed(2) : '' ));
            current_hype.raise().text(resolved_data_position_hype.hype);

        var current_hype = svg.select('.meta_box_volume_text');
            // current_hype.raise().text(parseFloat(d.hype) ? parseFloat(d.hype).toFixed(2) : '' );
            current_hype.raise().text(resolved_data_position_hype.volume ? resolved_data_position_hype.volume.toLocaleString() : '');

        // legend.transition().duration(300).style('opacity',1);
        // const lbody = legend.select("text");
        // lbody.text('');
        // legendFields.forEach((f,i) => {
        //     lbody.append('tspan').attr('x',0).attr('y', i* 23).text(f[0] + f.slice(1).replace('_',' ') + ': ' + d[f]);
        // });

    // if(!dateSelected) {
        draw_articles(props,Xi);
    //  }

    })
    .on('click', () =>  dateSelected = ~dateSelected )
    .on('mouseout', () => d3.select('.p-vert').style('opacity',0) +
                            d3.select('#p-hor1').style('opacity',0) +
                            d3.select('#p-hor2').style('opacity',0) +
                            d3.select('#pc1').style('opacity',0) +
                            d3.select('#pc2').style('opacity',0) 
    )
    .on('mouseover', () => d3.select('.p-vert').style('opacity',1) +
                            d3.select('#p-hor1').style('opacity',1) +
                            d3.select('#p-hor2').style('opacity',1) +
                            d3.select('#pc1').style('opacity',1) +
                            d3.select('#pc2').style('opacity',1)
    )
   // .append("g")
    

    svg = viz.select('svg g').attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");



    // Add bars

    var bars = svg.selectAll(".bar").data(price_data);

    var enterBars = bars.enter().append("rect")
        .attr("class", "bar")
        // .on("mouseout", () => tooltip.transition().duration(100).style('opacity',0) )
        .on("mousemove", (d) => {
            // tooltip = svg.select('.tooltip');
            // const text = tooltip.raise().select('text').text("DateTime: " + (d.datetime || 0));
            // text.append('tspan')
            //     .attr('y',12)
            //     .attr('x',6)
            //     .text('Hype: ' + d.hype);

            // text.append('tspan')
            //     .attr('y',12+12)
            //     .attr('x',6)
            //     .text('Price: ' + d.price);

            // text.append('tspan')
            //     .attr('y',24+12)
            //     .attr('x',6)
            //     .text('Volume: ' + d.volume );

            // const sizes = tooltip.select('text').node().getBoundingClientRect();
            // const coords = d3.mouse(svg.node());
            // const posX = width - coords[0] - sizes.width > 0 ? coords[0] + 15 : coords[0] - 25 - sizes.width;
            // tooltip.transition().duration(100)
            //     .style('opacity',1)
            //     .attr("transform", "translate(" + [posX,coords[1]-30] + ")")

            // tooltip.select('rect')
            //     .style('width', sizes.width+25)
            //     .style('height', sizes.height)

        });

    bars.exit().transition().duration(500).style('opacity',0).remove();
    bars.merge(enterBars)
        .transition().duration(500)
        .delay((d, i) => i)
        .attr("x", d => d.x = xBarScale(d.dateParsed) )
        .attr("y", d => heightBarSectionStart  +  yBars(d.volume) )
        .attr('rx',2)
        .attr('ry',2)
        // .attr("width", xBarScale.bandwidth())
        .attr("width", 3)
        .attr("height", d =>  heightBarSection - yBars(d.volume) );
        // .style("filter", "url(#glow)");
       // .each(d => console.log(d, yBars(d.hype)))

    var defs = svg.selectAll('defs').data([1]).enter().append('defs');
    
    //defs.enter().append("defs").merge(defs);


    // var defs = svg.select('defs');

    var hype_gradient = defs.append("linearGradient")
           .attr("id", "svgGradientHype")
           .attr("x1", "0%")
           .attr("x2", "100%")
           .attr("y1", "0%")
           .attr("y2", "100%");
        hype_gradient.append("stop")
           .attr('class', 'start')
           .attr("offset", "0%")
           .attr("stop-color", 'rgba(247, 147, 148, 0.22)')
           .attr("stop-opacity", 1);
        hype_gradient.append("stop")
           .attr('class', 'end')
           .attr("offset", "100%")
           // .attr("stop-color", "rgba(255, 54, 196, 0.47)")
           .attr("stop-color", "#a063c1")
           .attr("stop-opacity", 1);

    var price_gradient = defs.append("linearGradient")
           .attr("id", "svgGradientPrice")
           .attr("x1", "0%")
           .attr("x2", "100%")
           .attr("y1", "0%")
           .attr("y2", "100%");
        price_gradient.append("stop")
           .attr('class', 'start')
           .attr("offset", "0%")
           .attr("stop-color", 'rgba(175, 147, 247, 0.14)')
           .attr("stop-opacity", 1);
        price_gradient.append("stop")
           .attr('class', 'end')
           .attr("offset", "100%")
           .attr("stop-color", "#63b0c1")
           .attr("stop-opacity", 1);


    //Filter for the outside glow
    var filter = defs.append("filter")
        .attr("id","glow");
    filter.append("feGaussianBlur")
        .attr("stdDeviation","3.5")
        .attr("result","coloredBlur");
    var feMerge = filter.append("feMerge");
    feMerge.append("feMergeNode")
        .attr("in","coloredBlur");
    feMerge.append("feMergeNode")
        .attr("in","SourceGraphic");



    var lGrad = defs.append("linearGradient").attr("id", "rGradient")
            .attr("x1","0%").attr("y1","100%").attr("x2","0%").attr("y2","10%");
        lGrad.append("stop").attr("offset","0%").attr("stop-color","white").attr("stop-opacity","0");
        lGrad.append("stop").attr("offset","40%").attr("stop-color","white").attr("stop-opacity","1");
        lGrad.append("stop").attr("offset","100%").attr("stop-color","white").attr("stop-opacity","1");

    var mask = defs.append("mask").attr("id","Mask");

        mask.append("rect").attr("x",0).attr("y",0)
                .attr("width",W - mainW)
                .attr("height", height)
                .attr("fill", "url(#rGradient)")
       


/*
    var fshadow = defs.append("filter")
        .attr("id","dropshadow");

    fshadow.append("feOffset")
        .attr("dx","2")
        .attr("dy","2");

    fshadow.append("feGaussianBlur")
        .attr("stdDeviation","3")
        .attr("in","SourceAlpha")
        .attr("result","offsetblur")
    
    fshadow.append("feComponentTransfer")
        .append("feFuncA")
            .attr("type","linear")
            .attr("slope","0.5")
            
    var fshadow_m= fshadow.append("feMerge")

    fshadow_m.append("feMergeNode")

    fshadow_m.append("feMergeNode")
            .attr("in", "SourceGraphic")
  */        


    // Add the valueline path.
    var line = svg.selectAll('.line1').data([data]);

    line.enter().append("path")
        .attr("class", "line1")
        .merge(line)
        .transition().duration(500)
        .attr("d", valueline)
        .attr("stroke-width", 3)
        .attr("stroke", "url(#svgGradientHype)")
        .attr("fill", "none")
        .style("filter", "url(#glow)");

    // Add the valueline rolling average path.
    // DK TODO: put this back in. removed for having a proper demo
    // var linera = svg.selectAll('.line1ra').data([data]);

    // linera.enter().append("path")
    //     .attr("class", "line1ra")
    //     .merge(linera)
    //     .transition().duration(500)
    //     .attr("d", valueline_rolling_avg)
    //     .attr("stroke-width", 3)
    //     .attr("stroke", "url(#svgGradientHype)")
    //     .attr("fill", "none")
    //     .style("filter", "url(#glow)");


    // Add the valueline2 path.
    var line2 = svg.selectAll('.line2').data([price_data]);

    line2.enter().append("path")
        .attr("class", "line2")
        .merge(line2)
        .transition().duration(500)
        .attr("d", valueline2)
        .attr("stroke-width", 3)
        .attr("stroke", "url(#svgGradientPrice)")
        .attr("fill", "none")
        .style("filter", "url(#glow)");

    // Add the graph bottom part
    // var path1 = svg.selectAll('.path1').data([1]);

    // path1.enter().append("path")
    //     .attr("class", "path1")
    //     .merge(path1)
    //     .transition().duration(500)
    //     .attr("d", `M0,${heightBarSectionStart} v${heightBarSection} h${width} v${-heightBarSection}`);

    // Add the graph top section part
    // var path2 = svg.selectAll('.path2').data([1]);

    // path2.enter().append("path")
    //     .attr("class", "path2")
    //     .merge(path2)
    //     .transition().duration(500)
    //     .attr("d", `M0,0 v${heightTop-10} h${width} v${-heightTop+10} h${-width} h${width/3} v${heightTop-10}`);



    // Add the vert movable line
    var pVert = svg.selectAll('.p-vert').data([1]);

    pVert.enter().append("path")
        .attr("class", "p-vert")
        .merge(pVert)
        .attr("d", "M0,0");


    // Add the hor1 movable line
    var ph1 = svg.selectAll('#p-hor1').data([1]);

    ph1.enter().append("path")
        .attr("id", "p-hor1")
        .merge(ph1)
        .attr("d", "M0,0");

    // Add the hor2 movable line
    var ph2 = svg.selectAll('#p-hor2').data([1]);

    ph2.enter().append("path")
        .attr("id", "p-hor2")
        .merge(ph2)
        .attr("d", "M0,0");


    // Add the point text 1
    var pv = svg.selectAll('#p-volume').data([1]);

        pv.enter().append("text")
            .attr("id", "p-volume")
            .attr("class","p-volume")
            .merge(pv)
            .text();


    var pc1 = svg.selectAll('#pc1')
            .data([1])
            .enter()
            .append('g')
            .attr('id', 'pc1')
            .style('opacity',0);

        pc1.append("circle")
            .attr("id", "pc1outer")
            .attr("r", 13);

        pc1.append("circle")
            .attr("id", "pc1inner")
            .merge(pc1)
            .attr("r", 5)

    var pc2 = svg.selectAll('#pc2')
            .data([1])
            .enter()
            .append('g')
            .attr('id', 'pc2')
            .style('opacity',0);

        pc2.append("circle")
            .attr("id", "pc2outer")
            .attr("r", 13);

        pc2.append("circle")
            .attr("id", "pc2inner")
            .attr("r", 5)

    // Add the Y0 Axis
    var axisLeft = svg.selectAll('.axisLeft').data([1]);

        axisLeft.enter()
            .append("g")
            .attr('class','axisLeft')
            .merge(axisLeft)
            .transition()
            .call(d3.axisLeft(y0).ticks(4));

    // Add the point text 1
    var left_axis_text_marker = svg.selectAll('#left_axis_text_marker')
            .data([1])
            .enter()
            .append('g')
            .attr('id', 'left_axis_text_marker')
            .style('opacity',0);

        left_axis_text_marker.append('rect')
            .attr('id', 'axis_text_marker_dot');

        left_axis_text_marker.append('rect')
            .attr('id', 'axis_text_marker_box');

        left_axis_text_marker.append('text')
            .attr('id', 'axis_text_marker_text')
            .attr('dy',5)
            .attr('dx',40);


    // Add the X Axis
    var axisBottom = svg.selectAll('.axisBottom').data([1]);

        axisBottom.enter()
            .append('g')
            .attr('class','axisBottom')
            .attr("transform", "translate(" + [0,heightMain + heightMainStart] + ")")
            .call(d3.axisBottom(xhype).ticks(6));


    // Add the point text 3
    var bottom_axis_text_marker = svg.selectAll('#bottom_axis_text_marker')
            .data([1])
            .enter()
            .append('g')
            .attr('id', 'bottom_axis_text_marker')
            .style('opacity',0);

        bottom_axis_text_marker.append('rect')
            .attr('id', 'bottom_axis_text_marker_box');

        bottom_axis_text_marker.append('text')
            .attr('id', 'bottom_axis_text_marker_text')
            .attr('dy',5)
            .attr('dx',40);

    // Add the Y1 Axis

    var axisRight = svg.selectAll('.axisRight').data([1]);

        axisRight.enter()
            .append("g")
            .attr('class','axisRight')
            .attr("transform", "translate( " + width + ", 0 )")
            .merge(axisRight)
            .transition()
            .call(d3.axisRight(y1).ticks(4));



    // Add the point text 2
    var right_axis_text_marker = svg.selectAll('#right_axis_text_marker')
            .data([1])
            .enter()
            .append('g')
            .attr('id', 'right_axis_text_marker')
            .style('opacity',0);

        right_axis_text_marker.append('rect')
            .attr('id', 'right_axis_text_marker_dot');

        right_axis_text_marker.append('rect')
            .attr('id', 'right_axis_text_marker_box');

        right_axis_text_marker.append('text')
            .attr('id', 'right_axis_text_marker_text')
            .attr('dy',5)
            .attr('dx',40);


    // Add tooltip

    // tooltip = svg.selectAll('.tooltip').data([1])
    // // console.log('Print data for the DK:');
    // // console.log(tooltip);
    // // console.log('Print data for the DK:');
    //     .enter().append("g")
    //     .attr("class", "tooltip")
    //     .call((d) => {
    //         d.append("rect")
    //         .attr("y", -15)
    //         .attr('rx',2)
    //         .attr('ry',2)

    //         d.append("text")
    //         .attr("x", 6)
    //     })


    var nrect = svg.selectAll('.nrect').data([1]);

    nrect.enter().append("rect")
    .attr("class", "nrect")
    .on('mouseout', () => draw_articles(props) + console.log('OUTRECT'))
    .merge(nrect)
               
    .attr("x", 0 )
    .attr("y", 0)
    .attr("width", width)
    .attr("height", height )
    .style('fill', 'none')

    var article_data = svg.selectAll('#article-data').data([1])
        .enter().append("g")
        .attr('id', 'article-data')
        .attr("transform", "translate(" + (width + 50) + ", 10 )")        

     //   article_data.exit().remove();

    article_data.append("rect").classed('article-bg', true)
            .attr('height', height-20)
            .attr('width', W - mainW)
            .attr('rx', 10)
            .attr('ry', 10)
            .style('opacity', 0);
           // .attr("mask", "url(#Mask)")
          //  .attr("fill", "green")

    var asset_name = svg.selectAll('.asset_name').data([1]).remove();

    var asset_name_group = asset_name.enter()
            .append('g')
            .attr('class', 'asset_name')
            .merge(asset_name);

        asset_name_group.append('rect')
            .attr('class', 'asset_name_box');

        asset_name_group.append('text')
            .attr('class', 'asset_name_text')
            .attr('dx', 0)
            .attr('dy', 0)
            // .merge(asset)
            .text(asset.name);

    var meta_box = svg.selectAll('.meta_box').data([1]).remove();

    var meta_box_group = meta_box.enter()
            .append('g')
            .attr('class', 'meta_box')
            .merge(meta_box);

        // meta_box_group.append('rect')
        //     .attr('class', 'meta_box_dk');

        meta_box_group.append('text')
            .attr('class', 'meta_box_date_text')
            .attr('dx', 30)
            .attr('dy', 25);

        meta_box_group.append('text')
            .attr('class', 'meta_box_price_label')
            .attr('dx', 30)
            .attr('dy', 42)
            .text('USD');

        meta_box_group.append('text')
            .attr('class', 'meta_box_price_text')
            .attr('dx', 75)
            .attr('dy', 42);

        meta_box_group.append("circle")
            .attr("id", "meta_box_price_dot")
            .attr('cx', 10)
            .attr('cy', 39)
            .attr("r", 5);
            // .style("filter", "url(#glow)");

        meta_box_group.append('text')
            .attr('class', 'meta_box_hype_label')
            .attr('dx', 30)
            .attr('dy', 59)
            .text('HYPDX');

        meta_box_group.append('text')
            .attr('class', 'meta_box_hype_text')
            .attr('dx', 75)
            .attr('dy', 59);

        meta_box_group.append("circle")
            .attr("id", "meta_box_hype_dot")
            .attr('cx', 10)
            .attr('cy', 56)
            .attr("r", 5);
            // .style("filter", "url(#glow)");

        meta_box_group.append('text')
            .attr('class', 'meta_box_volume_label')
            .attr('dx', 30)
            .attr('dy', 76)
            .text('VOL');

        meta_box_group.append('text')
            .attr('class', 'meta_box_volume_text')
            .attr('dx', 75)
            .attr('dy', 76);

        meta_box_group.append("rect")
            .attr("id", "meta_box_volume_dot")
            .attr('x', 8)
            .attr('y', 68)
            .attr("rx", 2)
            .attr("r", 2);
            // .style("filter", "url(#glow)");

    // d3.json('https://serve.hypeindex.io/frontend/assets_list.json').then( dkata => {
    //     // var nodes = svg.selectAll('.asset_select_button')
    //     var nodes = svg.selectAll('.asset_select_list')
    //         .data(dkata)
    //         .enter()
    //         .append('g')
    //         // .append('div')
    //         .classed('asset_select_list', true)
    //         .on('click', () =>  dk() );

    //     nodes.append("rect")
    //         .attr('class', 'asset_select_button_box')
    //         .attr('class', function(d,i) {return (i%2) ? 'asset_select_button_box_white' : 'asset_select_button_box_grey'})
    //         .attr("y", function(d,i) {return (i*25)});

    //     nodes.append('text')
    //         .attr('class', 'asset_select_button_name')
    //         .attr('dx', 75)
    //         .attr("dy", function(d,i) {return (i*25)+15})
    //         .text(function(d, i) {
    //             return d.name;
    //         });

    //     nodes.append('text')
    //         .attr('class', 'asset_select_button_symbol')
    //         .attr('dx', 25)
    //         .attr("dy", function(d,i) {return (i*25)+15})
    //         .text(function(d, i) {
    //             return d.symbol;
    //         });

    // })


  // Add asset data

  /* var assetText = svg.selectAll('.asset-text').data([1]);

       assetText.exit().transition().duration(200).style('opacity',0).remove();

     assetText.enter().append("text")
     .attr("class", "asset-text")
     .attr('x',width/3/2)
     .attr('y',heightTop/2.2 )
     .merge(assetText)
         .text(asset.name )

        .append('tspan')
         .attr('dy',24)
         .attr('x',width/3/2)
         .text('(' + asset.symbol + ')')

         .call((d) => {
             if(!d || !d.node()) return;
             const w = d.node().parentNode.getBoundingClientRect().width;

             if(w > width/3.2) {
                 d3.select(d.node().parentNode).transition().duration(500).style('font-size', (17 / ( w/ (width/3.2))) + 'px');
             }else{
                 d3.select(d.node().parentNode).transition().duration(500).style('font-size', 17 + 'px');
             }

        })
*/

  //   // Add legend/tooltip part

  //   svg.selectAll('.legend').data([1])
  //       .enter().append("g")
  //       .attr("class", "legend")
  //       .attr("transform", "translate(" + [width/3*2 , 32] + ")")
  //       .append('text').text('');

  //   legend  = svg.select('.legend').style('opacity',0)

}

function findPos(lineNode,X) {

    var beginning = X,
        end = lineNode.getTotalLength(),
        target,
        pos;
        if(!end) return 0;
    while (true) {
        target = Math.floor((beginning + end) / 2);
        pos = lineNode.getPointAtLength(target);
        if ((target === end || target === beginning) && pos.x !== X) {
            break;
        }
        if (pos.x > X) end = target;
        else if (pos.x < X) beginning = target;
        else break;
    }
    return pos.y;
  }

export {draw,draw_articles};